import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ThemeSettingsService } from '../settings/theme-settings.service';
import { Subject } from 'rxjs';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { NavbarService } from 'src/app/_services/navbar.service';
import { DOCUMENT } from '@angular/common';
import { User } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  layout: string;
  private _themeSettingsConfig: any;
  private _unsubscribeAll: Subject<any>;
  loggedInUser: User;
  isMobile = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _themeSettingsService: ThemeSettingsService,
    private authService: AuthService,
    private navbarService: NavbarService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    const self = this;
    // this.isMobile = window.innerWidth < AppConstants.RESPONSIVE_WIDTH_HORIZONTAL;
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        console.log('nav-themsetting',config)
        this._themeSettingsConfig = config;
        if (config.layout && config.layout.style &&
          config.layout.style === 'vertical') {
          self.layout = 'vertical';
        } else {
          self.layout = 'horizontal';
        }
      });

    this.handleCollapsibleMenu(window.innerWidth);
    this.loggedInUser = this.authService.getLoggedInUser();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleCollapsibleMenu(event.target.innerWidth);
  }

  handleCollapsibleMenu(width) {
    if (this._themeSettingsConfig.menu === 'collapse') {
      // show the left aside menu
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove('menu-expanded');
      this.document.body.classList.add('menu-collapsed');
      if (width < AppConstants.RESPONSIVE_WIDTH_HORIZONTAL_COLLAPSED) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    } else {
      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove('menu-collapsed');
      this.document.body.classList.add('menu-expanded');
      if (width < AppConstants.RESPONSIVE_WIDTH_HORIZONTAL_EXPANDED) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
    const previosBodyClassList = [].slice.call(document.body.classList);
  }  
}
