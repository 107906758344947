<ng-template #modalIconContent let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel2"><i class="la la-road2"></i>Basic Modal
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5><i class="la la-arrow-right"></i> Check First Paragraph</h5>
      <p>Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple
        pie.
        Brownie carrot
        cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake
        cookie
        chocolate cake
        liquorice. Apple pie sugar plum powder donut soufflé.</p>
      <p>Sweet roll biscuit donut cake gingerbread. Chocolate cupcake chocolate bar ice
        cream.
        Danish candy
        cake.</p>
      <hr>
      <h5><i class="la la-lightbulb-o"></i> Some More Text</h5>
      <p>Cupcake sugar plum dessert tart powder chocolate fruitcake jelly. Tootsie roll
        bonbon
        toffee danish.
        Biscuit sweet cake gummies danish. Tootsie roll cotton candy tiramisu lollipop
        candy
        cookie biscuit pie.</p>
      <div class="alert alert-success" role="alert">
        <span class="text-bold-600">Well done!</span> You successfully read this important
        alert
        message.
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary"
        (click)="d('Close modal')">Close</button>
      <button type="button" class="btn btn-outline-primary">Save changes</button>
    </div>
  </ng-template>