<div (window:resize)="onResize($event)">
	<app-header></app-header>
	<ng-container *ngIf="layout === 'vertical' || isMobile == true">
		<app-verticalnav></app-verticalnav>
	</ng-container>

	<ng-container *ngIf="layout === 'horizontal' && isMobile == false">
		<app-horizontalnav></app-horizontalnav>
	</ng-container>
      <div class="app-content content header-menu" [hidden]="isMobile">
        <span class="li-title mr-1 place-order">PLACE ORDER</span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/copy" routerLinkActive="active-link" >Copy</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/print" routerLinkActive="active-link">Print</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/scan" routerLinkActive="active-link">Scan</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/court-appeal" routerLinkActive="active-link">Appeal & Court Book</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/court-copy" routerLinkActive="active-link">Court Copy</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/multimedia-duplication" routerLinkActive="active-link">Multimedia Duplication</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/eFiling" routerLinkActive="active-link">eFiling</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/eDiscovery" routerLinkActive="active-link">eDiscovery</a>
        </span>
        <span class="nav-item mr-1 menu-item">
          <a routerLink="/orders/hardcopy-processing" routerLinkActive="active-link">Hard Copy Processing</a>
        </span>
        <span class="nav-item mr-1 menu-item float-right pull-right contact-number">
          <img src="../../../assets/custom/images/li_full_red-small.png" class="li-logo">
        </span>
      </div>
</div>
