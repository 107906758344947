import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from '../../_models/user';

import *  as firebase from 'firebase';
import { refCount, switchAll } from 'rxjs/operators';
import { Groups } from 'src/app/_models/constants/setting';

@Injectable({
  providedIn: 'root'
})


export class UsersService {

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
    ) { 
      // functions.functions.useFunctionsEmulator("http://localhost:5001");
    }

  HISTORY_LOCATION_CHANGE = "changeHistory";
  HISTORY_LOCATION_ACCESS = "accessHistory";
      
  usersRef = this.firestore.collection('users');

  public getInternalUsers(){
    return this.firestore.collection<User>('users', ref => ref.where('group', '==', 'InternalUser').orderBy('displayName')).snapshotChanges();
  }

  public getExternalUsers(companyID?: string){
    if (companyID)
      return this.firestore.collection('customers').doc(companyID).collection<User>('users', ref => ref.orderBy('displayName'))
                            .snapshotChanges();
    else
      return this.firestore.collection<User>('users', ref => ref.where('group', '==', 'ExternalUser').orderBy('displayName')).snapshotChanges();
  }

  public getUsersByCompany(companyId) {
    return this.firestore.collection('customers').doc(companyId).collection<User>('users', ref=>ref.orderBy('displayName')).snapshotChanges();
}

  public getUserGroupRolePolicy (userId) {
      return this.firestore.collection('userGroupAndRoles').doc(userId).get();
  }

  public checkInternalUser(userId): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.usersRef.doc(userId).get().subscribe((user)=>{
        if (user.data().type == "InternalUser")
          resolve(true);
        else
          resolve(false);
      },error=>{
        reject(error);
      })
    });
  }

  public async checkSpecialUser(userId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.usersRef.doc(userId).get().subscribe(user=>{
        if (user.data().special)
          resolve(true);
        else
          resolve(false);
      },error=>{
        reject(error);
      })
    });
  }

  public async checkAdmin(userId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.usersRef.doc(userId).get().subscribe(user=>{
        if (user.data().admin)
          resolve(true);
        else
          resolve(false);
      },error=>{
        reject(error);
      })
    })
  }

  public async checkAccountant(userId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.usersRef.doc(userId).get().subscribe(user=>{
        if (user.data().accountant)
          resolve(true);
        else
          resolve(false);
      },error=>{
        reject(error);
      })
    })
  }

  public getBusinessDetails() {
    return this.firestore.collection('siteConfig').doc('businessDetails').get();
  }

  public createUser(user:User, password: string):Promise<any> {
    return new Promise((resolve, reject) => {
      function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
      } 
      var rString = randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');    
      if (!password) {
        password = rString;
      } 

      delete user['password'];
      delete user['reEnterPassword'];
      let newUser = {
          "user": user,
          "password": password
      }
    
      const createNewUser = this.functions.functions.httpsCallable('createUser')
      createNewUser(newUser).then(res=>{
        resolve(res);
      }).catch(e=>{
        reject(e);
      })
    });
  }

  public getUserById(userId: string, group: Groups) {
    return new Promise((resolve, reject) => {
      
      let data = {
          "uid": userId,
      }
      
      switch (group) {
        case Groups.InternalUser:
          const getUser = this.functions.functions.httpsCallable('getUser')
          getUser(data).then(res=>{
            resolve(res);
          }).catch(e=>{
            reject(e);
          })
          break;
        case Groups.ExternalUser:
          const getMyStaff = this.functions.functions.httpsCallable('getMyStaff')
          getMyStaff(data).then(res=>{
            resolve(res);
          }).catch(e=>{
            reject(e);
          })
          break;
      }
    });
  }

  public getUserByIdV2(userId: string) {
    return this.usersRef.doc(userId).get();

  }


  public updateUser(user: User, password: string) {
    return new Promise((resolve, reject) => {
      delete user['password'];
      delete user['reEnterPassword'];
console.log('update user', user);
      let data ={
        user: user,
        password: password
      }

      const updateUser = this.functions.functions.httpsCallable('updateUser')
      updateUser(data).then(res=>{
        resolve(res)
      }).catch(e=>{
        reject(e);
      })
    });

  }

  public setUserActive(uid: string, active: boolean) {
    return new Promise((resolve, reject) => {
      let data = {
        uid: uid,
        active: active,
      }
      const userActive = this.functions.functions.httpsCallable('setUserActive')
      userActive(data).then(res=>{
        resolve(res);
      }).catch(e=>{
        reject(e);
      })
    });

  } 
  
  public applyGroupPolicy(user:User) {
    delete user['password'];
    delete user['reEnterPassword'];
    
    return new Promise((resolve, reject) => {
      const groupPolicyUpdate = this.functions.functions.httpsCallable('applyGroupPolicy')
      groupPolicyUpdate(user).then(res=>{
        resolve(res);
      }).catch(e=>{
        reject(e);
      })
    });

  }   

  public getUserChangeHistory(uid) {
    return this.usersRef.doc(uid).collection(this.HISTORY_LOCATION_CHANGE, ref=>ref.orderBy('dateTime', 'desc')).snapshotChanges();
  }

  public getUserAccessHistory(uid) {
    return this.usersRef.doc(uid).collection(this.HISTORY_LOCATION_ACCESS, ref=>ref.orderBy('dateTime', 'desc')).snapshotChanges();
  }
}    
