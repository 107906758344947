import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwIfEmpty } from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  passwordResetForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  resetFlow: boolean;
  params: any;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      public authService: AuthService) { }

  ngOnInit() {
      this.passwordResetForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: []
      });

      this.route.queryParams.subscribe(params=>{
        this.params = params;
        if (params.mode) {
          switch (params.mode) {
            case 'resetPassword':
              this.resetFlow = true;
              this.verifyPasswordResetCode();

          }
        }
      })

        

  }

  // convenience getter for easy access to form fields
  get f() { return this.passwordResetForm.controls; }

  verifyPasswordResetCode() {
    this.authService.doVerifyPasswordResetCode(this.params.oobCode).then(email=>{
      this.f.email.setValue(email);
      this.f.password.setValidators([Validators.required, Validators.pattern(new RegExp("^[A-Za-z0-9_@].{3,8}$"))]);
      this.f.password.updateValueAndValidity();
      this.f.email.disable();
    }, err=>{
      this.alertService.error(err.message);
    })
  }

  handlePasswordResetClick() {
      if (this.params.mode){
        switch (this.params.mode) {
          case 'resetPassword':
            this.tryResetPassword();
            break;
        }
      } else {
        this.trySendPasswordResetEmail();
      }
  }

  trySendPasswordResetEmail() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.passwordResetForm.invalid) {
        return;
    } 
    const value = {
        email: this.f.email.value,
    };

    this.loading = true;

    this.authService.doSendPasswordResetEmail(value)
    .then(res => {
      this.alertService.success('Password reset email has been sent to the email address.')
      this.loading = false;
      this.submitted = false;
    }, err => {
        this.submitted = false;
        this.loading = false;
        this.alertService.error(err.message);
    });

  }

  tryResetPassword() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.passwordResetForm.invalid) {
        return;
    }

    const value = {
        password: this.f.password.value,
        oobCode: this.params.oobCode
    };

    this.loading = true;
    this.authService.doResetPassword(value)
    .then(res => {
      this.alertService.success('Password has been successfully reset. Please login.', true)
      this.router.navigate(['/login']);
  this.submitted = false;
      this.loading = false;
    }, err => {
        this.submitted = false;
        this.loading = false;
        this.alertService.error(err.message);
    });

  }

}