import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { Error404Component } from './content/full-pages/error/error404/error404.component';
const routes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: '', component: LoginComponent },

    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'users', loadChildren: () => import('./content/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard], canActivateChild:[AuthGuard] },
      { path: 'error', loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
      , canActivate: [AuthGuard] },
      { path: 'orders', loadChildren: () => import('./content/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
      { path: 'tv-dashboard', loadChildren: () => import('./content/tv-dashboard/tv-dashboard.module').then(m => m.TvDashboardModule), canActivate: [AuthGuard] },
      { path: 'customers', loadChildren: () => import('./content/customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthGuard], canActivateChild:[AuthGuard] },
      { path: 'rate-card', loadChildren: () => import('./content/rate-card/rate-card.module').then(m => m.RateCardModule), canActivate: [AuthGuard] },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'orders' }
];

// export const AppRoutingModule = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
