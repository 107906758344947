import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { GroupPolicies, Groups, Policy, Roles, PolicyScore } from '../_models/constants/setting';
import { AuthService } from '../_services/auth.service';
import { UsersService } from '../_services/users/users.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let stateURL = state.url;
    let loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser) {

      return true;

      this.router.navigate(['/error/error404']);
      return false;
    }

    // Not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let stateURL = state.url;
    let loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser) {

      let policy :Policy = loggedInUser.policy;
      let path = stateURL.split('/');
      console.log(policy, path)

      //Accountant and Super have all access
      if ((policy.group === Groups.InternalUser) && (policy.score >= PolicyScore.Accountant)){
        return true;
      }

      let accessStatus = false;

      switch (policy.group){
        case Groups.InternalUser:
          accessStatus =  await this.handleInternalUserAccess(policy, path);
          break;
        case Groups.ExternalUser:
          accessStatus = await this.handleExternalUserAccess(policy, path);
          break;
      }


      if (accessStatus) {
        return accessStatus;
      } else {
        //Default cases fail all
        this.router.navigate(['/error/error404']);
        return accessStatus;
      }

    } else {
      // Not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  handleExternalUserAccess(policy: Policy, path: any): Promise<boolean> {
    let accessStatus = false;
console.log(path)
    return new Promise ((resolve, reject)=>{
      if (path[1] === "users" && (path[2].search("my-staff")==0) ||(path[2].search("add-staff")==0) || (path[2].search("manage-staff")==0))
      {
        accessStatus = true;
      }
      resolve (accessStatus)
    })
  }

  handleInternalUserAccess(policy: Policy, path: any) :Promise <boolean>{
    let accessStatus = false;

    return new Promise ((resolve, reject)=>{
      if (path[1] === "users" && (path[2].search("internal-users")==0) ||(path[2].search("add-internal-user")==0))
      {
        accessStatus = true;
      }
      resolve (accessStatus)
    })
  }

}
