import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AccessRequest } from '../_models/access-request';
import { ErrorMessages } from 'src/app/_helpers/app.errorMessages';
import { GroupPolicies, Groups, Policy } from '../_models/constants/setting';
import { User } from '../_models/user';



@Injectable()
export class AuthService {
  enquiriesRef = this.firestore.collection('enquiries');
  usersRef = this.firestore.collection('users');

  constructor(
   public afAuth: AngularFireAuth,
   private firestore: AngularFirestore,
 ) { }





  public getLoggedInUser(): User {
      return <User>JSON.parse(localStorage.getItem('currentUser'));
  }

  public isSuper(): Boolean {
    let user = <User>JSON.parse(localStorage.getItem('currentUser'));
    if (user.policy.groupPolicy == GroupPolicies.InternalSuper && user.policy.score >= 30) 
      return true;
    else
      return false;
  }

  public isAdmin(): Boolean {
    let user = <User>JSON.parse(localStorage.getItem('currentUser'));
    if (user.policy.group == Groups.InternalUser && user.policy.score >= 10) 
      return true;
    else
      return false;
  }

  public getUID(): string{
    return (<User>JSON.parse(localStorage.getItem('currentUser'))).uid;
  }
  
  getLoggendInUserGroupPolicy() : Promise<Policy>{
    let policy: Policy;

    return new Promise<Policy>(async (resolve, reject) => {
      if (this.afAuth.auth.currentUser){
        let idTokenResult = await this.afAuth.auth.currentUser.getIdTokenResult();
        if (idTokenResult.claims) {
          policy = {
            group: idTokenResult.claims.group,
            role: idTokenResult.claims.role,
            groupPolicy: idTokenResult.claims.groupPolicy,
            score: idTokenResult.claims.score,
            companyId: idTokenResult.claims.companyId
          }
        } else {
          policy = null;
        }
        resolve(policy);
      } else {
        reject(null);
      }    
    })
  }

  doRequestAccess(value: AccessRequest) {
    return new Promise<any>((resolve, reject) => {
      value = { ...value, requestDateTime: new Date()}
      this.enquiriesRef.add(value).then(res=>{
        resolve(res)
      }, err=> reject(err));
    })

  }

  doSendPasswordResetEmail(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.auth.sendPasswordResetEmail(value.email).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doVerifyPasswordResetCode(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.auth.verifyPasswordResetCode(value).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doResetPassword(value) {
    return new Promise<any>((resolve, reject)=>{
      this.afAuth.auth.confirmPasswordReset(value.oobCode, value.password).then(res=>{
        resolve(res)
      }, err=>{
        reject(err);
      })
    })
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
      .then(async res => {
        let policy = await this.getLoggendInUserGroupPolicy();
        //start listner for auth changes TODO
        this.usersRef.doc(res.user.uid).get().subscribe(resp=>{

          console.log('login', resp.data())
          if (resp.data() !== undefined) {
            let response = {uid: res.user.uid, policy:policy, ...resp.data()};
            this.setUserInStorage(response);
            resolve(response);
          }
          else  {
            reject ({ message: ErrorMessages.ERROR_USER_NOT_FOUND})
          }
        }, err => reject (err));
      }, err => reject(err));
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (this.afAuth.auth.currentUser) {
        this.afAuth.auth.signOut().then(res=>{
          this.removeUserFromStorage();
          resolve(res);
        }, error=>{
          reject(error);
        });
      }
    });
  }


  setUserInStorage(res) {
    // this.usersService.getUserGroupRolePolicy(res.uid).subscribe(policy=>{
      // if (policy.data()) {
        // let user = { policy: policy.data(), ...res}
          localStorage.setItem('currentUser', JSON.stringify(res));
        // }

        // sessionStorage.setItem('currentUser', JSON.stringify(res));
      // }
    // })
  }

  removeUserFromStorage() {
    localStorage.removeItem('currentUser');
  }
  
}
